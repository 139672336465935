<template>
	<Offer>
		<template #banner>
			<img slot="banner" v-if="mobile === false" src="../../../../../assets/images/banners/procedural.png" alt="banner">
			<img slot="banner" v-else src="../../../../../assets/images/mobile/procedural.png" alt="banner">
		</template>

		<template #button>
			<i v-if="mobile === true" class="fas fa-arrow-left"></i>
			<router-link slot="button" to="/oferta">Wróć do spisu usług</router-link>
		</template>

		<template #small-title>
			<h4>PRAWO PRACY</h4>
		</template>

		<template #title>
			<h2>Procesowe prawo pracy / reprezentacja przed organami</h2>
		</template>

		<template #text>
			<p>Obszar procesowego prawa pracy i reprezentacji przed organami obejmuje usługi w zakresie:
			</p>
		</template>

		<template #range>
			<h3>Zakres:</h3>
		</template>
		<template #list>
			<ul class="offer__list">
				<li class="offer__list-item">pełnego zastępstwa procesowe Pracodawcy w sporach z pracownikami lub
					związkami zawodowymi przed sądami pracy i ubezpieczeń społecznych, jak np. sprawy z odwołania
					pracowników od rozwiązania umów o pracę, o mobbing, o wynagrodzenie z tytułu pracy
					w godzinach nadliczbowych, o ustalenie nieistnienia związku zawodowego, o ustalenia nieistnienia
					sporu zbiorowego oraz sądami cywilnymi, jak np. sprawy o uzupełniające roszczenia cywilne przeciwko
					pracownikom lub z powództwa pracowników, o naruszenie dóbr osobistych);


				</li>
				<li class="offer__list-item">pełnego reprezentowania Pracodawcy w relacjach z właściwymi organami,
					takimi jak Państwowa Inspekcja Pracy (np. wparcie Pracodawcy w postępowaniu kontrolnym inspektora
					pracy, sporządzanie odpowiedzi na wystąpienia pokontrolne, sporządzanie wniosków o wydanie
					interpretacji przepisów) Zakład Ubezpieczeń Społecznych (np. sporządzanie wniosków o wydanie
					interpretacji przepisów) czy urzędami pracy.
				</li>
			</ul>
		</template>
	</Offer>
</template>

<script>
import Offer from '../Offer'
import {handleWith} from "@/mixins/globalMixins";

export default {
	mixins: [handleWith],
	components: {
		Offer
	},
	name: 'procedural labor law',
}
</script>
